import React,  { useState } from "react";
import Header from "../components/header/header";
// import goose from "../../static/web_goose.jpg";
import indexStyles from './index.module.css';
import Helmet from "react-helmet";

export default function Home() {
  const [open, setOpen] = useState(false);

  return (
    <div className='index'>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Julia Edwards</title>
      </Helmet>
      <Header page='splash' open={open} setOpen={setOpen}/>
      <div className='content'>
      <img src={'/web_goose.jpg'} className={indexStyles.splashPhoto}></img>
      </div>
    </div>
  )
}
